import { useCallback } from 'react';
// @mui
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// components
import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

import { useResponsive } from 'src/hooks/use-responsive';
// ----------------------------------------------------------------------

export const allLangs = [
    {
        label: 'English',
        value: 'en',
        icon: 'flagpack:gb-nir'
    },
    {
        label: 'French',
        value: 'fr',
        icon: 'flagpack:fr'
    },
    {
        label: 'Vietnamese',
        value: 'vi',
        icon: 'flagpack:vn'
    },
    {
        label: 'Chinese',
        value: 'cn',
        icon: 'flagpack:cn'
    },
    {
        label: 'Arabic',
        value: 'ar',
        icon: 'flagpack:sa'
    }
];

export default function LanguagePopover() {
    const popover = usePopover();

    const currentLang = allLangs[1];

    const lgUp = useResponsive('up', 'lg');

    const handleChangeLang = useCallback(() => {
        popover.onClose();
    }, [popover]);

    return (
        <>
            {/* <IconButton
                component={m.button}
                whileTap="tap"
                whileHover="hover"
                variants={varHover(1.05)}
                onClick={popover.onOpen}
                sx={{
                    width: 40,
                    height: 40,
                    ...(popover.open && {
                        bgcolor: 'action.selected'
                    })
                }}
            > */}
            {lgUp ? (
                <IconButton
                    onClick={popover.onOpen}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        padding: 0
                    }}
                >
                    <Typography color="white" fontWeight={400} fontSize="0.875rem">
                        {currentLang.value.toUpperCase()}
                    </Typography>
                    <Iconify icon={currentLang.icon} sx={{ borderRadius: 0.65, width: '1rem', height: '1rem' }} />
                    <Image
                        src="/assets/icons/navbar/ic_baseline-play-arrow.svg"
                        sx={{
                            color: 'text.secondary',
                            cursor: 'pointer',
                            transition: (theme) =>
                                theme.transitions.create('color', {
                                    duration: 250
                                }),
                            '&:hover': {
                                color: 'text.primary'
                            },
                            '& span': {
                                display: 'flex !important'
                            }
                        }}
                    />
                </IconButton>
            ) : (
                <IconButton
                    onClick={popover.onOpen}
                    sx={{
                        borderRadius: 0.75,
                        display: 'flex',
                        alignItems: 'center',
                        '& svg': {
                            width: '25px',
                            height: '25px'
                        }
                    }}
                >
                    <Iconify icon={currentLang.icon} sx={{ borderRadius: 0.65 }} />
                </IconButton>
            )}

            <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 160 }}>
                {allLangs.map((option) => (
                    <MenuItem
                        key={option.value}
                        selected={option.value === currentLang.value}
                        onClick={handleChangeLang}
                    >
                        <Iconify icon={option.icon} sx={{ borderRadius: 0.65, width: 28 }} />

                        {option.label}
                    </MenuItem>
                ))}
            </CustomPopover>
        </>
    );
}
