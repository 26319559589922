import { useMemo, useEffect, useCallback, useState } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import Label from 'src/components/label';
import Image from 'src/components/image';
import SvgColor from 'src/components/svg-color';
// auth
import { useAuthContext } from 'src/auth/hooks';
// apis
import { getSelectCateogry } from 'src/api/game';

// ----------------------------------------------------------------------

const icon = (name: string) => (
    <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
    // OR
    // <Iconify icon="fluent:mail-24-filled" />
    // https://icon-sets.iconify.design/solar/
    // https://www.streamlinehq.com/icons
);

const ICONS = {
    sports: icon('ic_sports'),
    live_sports: icon('ic_live_sports'),
    casino: icon('ic_casino'),
    live_casino: icon('ic_live_casino'),
    virtual: icon('ic_virtual'),
    racing: icon('ic_racing'),
    e_sports: icon('ic_e_sports'),
    tournaments: icon('ic_tournaments'),
    mini_games: icon('ic_mini_games'),
    promotions: icon('ic_promotions'),
    contact_us: icon('ic_contact_us'),
    aviator: icon('aviator'),
    upgaming: icon('upgaming')
};

const itemArray = [
    {
        title: 'Sportbook',
        path: paths.sports,
        // icon: ICONS.sports,
        id: 'sport'
    },
    {
        title: 'Casino',
        path: paths.casino,
        info: (
            <Label
                color="error"
                sx={{
                    position: 'absolute',
                    top: -5,
                    right: -8,
                    height: 17,
                    fontSize: '10px',
                    borderRadius: '3px !important',
                    background: '#c22327',
                    color: '#FFFFFF',
                    boxShadow: '0 0 10px rgba(0,0,0,0.2)'
                }}
            >
                New
            </Label>
        )
        // icon: ICONS.casino
    },

    {
        title: 'Live Casino',
        path: paths.live_casino
        // icon: ICONS.live_casino
    },
    {
        title: 'Virtual',
        path: paths.virtual,
        info: (
            <Label
                color="error"
                sx={{
                    position: 'absolute',
                    top: -5,
                    right: -8,
                    height: 17,
                    fontSize: '10px',
                    borderRadius: '3px !important',
                    background: '#c22327',
                    color: '#FFFFFF'
                }}
            >
                New
            </Label>
        )
        // icon: ICONS.virtual
    },
    {
        title: '',
        path: paths.aviator,
        info: (
            <Image
                src="/assets/icons/navbar/aviator.svg"
                sx={{
                    color: 'text.secondary',
                    cursor: 'pointer',
                    transition: (theme) =>
                        theme.transitions.create('color', {
                            duration: 250
                        }),
                    '&:hover': {
                        color: 'text.primary'
                    }
                }}
            />
        )
    },
    {
        title: 'Upgaming',
        path: paths.chicken,
        icon: ICONS.upgaming
    }

    // { title: 'Mini Games', path: paths.mini_games, icon: ICONS.contact_us },
    // {
    //     title: 'E-Sports',
    //     path: paths.e_sports,
    //     icon: ICONS.e_sports
    // },
    // {
    //     title: 'Lotteries',
    //     path: paths.italianlottery
    //     // icon: ICONS.promotions,
    // },

    // {
    //     title: 'Racing',
    //     path: paths.racing
    // },
    // {
    //     title: 'E-Sports',
    //     path: paths.sports,
    //     // icon: ICONS.sports,
    //     id: 'sport',
    //     info: (
    //         <Label
    //             color="error"
    //             sx={{
    //                 position: 'absolute',
    //                 top: -7,
    //                 right: -6,
    //                 height: 17,
    //                 fontSize: '10px',
    //                 borderRadius: '3px !important',
    //                 background: '#c22327',
    //                 color: '#FFFFFF'
    //             }}
    //         >
    //             New
    //         </Label>
    //     )
    // }

    // {
    //     title: 'Contact',
    //     path: paths.contact_us,
    //     icon: ICONS.contact_us
    // }
];

// ----------------------------------------------------------------------

export function useNavData() {
    const { user } = useAuthContext();
    const [initArray, setInitArray] = useState<any>([]);
    const init = useCallback(async () => {
        if (user) {
            const res = await getSelectCateogry();
            if (res.length !== 0) {
                const resItem = itemArray.filter(
                    (item, index) =>
                        (index === 0 && res.sport && item) ||
                        (index === 1 && res.casino && item) ||
                        (index === 2 && res.casinovip && item) ||
                        (index === 3 && res.livecasino && item) ||
                        (index === 4 && res.virtual && item) ||
                        (index === 5 && res.aviator && item) ||
                        (index === 6 && res.chicken && item) ||
                        (index === 7 && res.racing && item) || // Add your condition for index 7 here
                        (index === 8 && res.turbogames && item) ||
                        (index === 9 && res.italianlottery && item)
                );
                setInitArray(resItem);
            } else {
                setInitArray(itemArray);
            }
        } else {
            setInitArray(itemArray);
        }
    }, [user, setInitArray]);

    useEffect(() => {
        init();
    }, [init]);

    const data = useMemo(
        () => [
            // OVERVIEW
            // ----------------------------------------------------------------------
            {
                subheader: '',
                items: initArray
            }
        ],
        [initArray]
    );

    return data;
}
