import { useState } from 'react';
import { Box } from '@mui/system';
import { useTheme } from '@mui/material/styles';

// @mui
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
// dialog
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// config
import { ASSETS_API } from 'src/config-global';
// components
import Image from 'src/components/image';
import { useSettingsContext } from 'src/components/settings';
import { useResponsive } from 'src/hooks/use-responsive';
import Carousel, { useCarousel } from 'src/components/carousel';

// import { useResponsive } from 'src/hooks/use-responsive';
// import footer icons
import { _carousels, footerInfo, FooterLinks, footerHeadIcons, footerMobileInfo } from './icons/footer';

const Footer = () => {
    // const isMdUp = useResponsive('up', 'md');

    const settings = useSettingsContext();

    const [open, setOpen] = useState(false);

    const lgUp = useResponsive('up', 'lg');
    const smUp = useResponsive('up', 'sm');
    const mediaOnly = useResponsive('only', 'xs');
    const handleClose = () => {
        setOpen(false);
    };

    const openLicense = () => {
        console.log(1234);
        setOpen(true);
    };

    const carousel = useCarousel({
        autoplay: true,
        slidesToShow: 14,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 4
                }
            }
        ]
    });

    const renderHead = (
        <Stack direction="row" justifyContent="space-between" pb={6}>
            <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                flexGrow={{ xs: 1, md: 0 }}
                justifyContent={{ xs: 'space-evenly' }}
            >
                {lgUp && <Image src={footerHeadIcons[0]} sx={{ width: 135 }} />}
                {/* <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    width={{ xs: '170px' }}
                    sx={{ bgcolor: '#0C1221', borderRadius: '10px', px: { md: 3, xs: 4 }, py: { md: 0.6, xs: 1 } }}
                >
                    <Image src={footerHeadIcons[1]} sx={{ width: 22.12, height: 26.25 }} />
                    <Stack>
                        <Typography sx={{ fontSize: 10 }}>Download</Typography>
                        <Typography sx={{ fontSize: 10 }}>app for IOS</Typography>
                    </Stack>
                </Stack>

                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    width={{ xs: '170px' }}
                    sx={{ bgcolor: '#0C1221', borderRadius: '10px', px: { md: 3, xs: 4 }, py: { md: 0.6, xs: 1 } }}
                >
                    <Image src={footerHeadIcons[2]} sx={{ width: 22.12, height: 26.25 }} />
                    <Stack>
                        <Typography sx={{ fontSize: 10 }}>Download</Typography>
                        <Typography sx={{ fontSize: 10 }}>app for Android</Typography>
                    </Stack>
                </Stack> */}
            </Stack>
            {lgUp && (
                <Stack direction="row" spacing={2}>
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        sx={{ bgcolor: '#0C1221', borderRadius: '5px', px: 2, py: 1 }}
                    >
                        <Image src={footerHeadIcons[3]} />
                        <Typography>English</Typography>
                    </Stack>
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        sx={{ bgcolor: '#0C1221', borderRadius: '5px', px: 2, py: 1 }}
                    >
                        <Image src={footerHeadIcons[4]} />
                        <Typography>Support</Typography>
                    </Stack>
                </Stack>
            )}
        </Stack>
    );

    const renderList = (
        <Stack direction={{ xs: 'column', sm: 'row' }} gap={2} justifyContent="space-between" pb={4}>
            {FooterLinks.map((item: any, index: number) => (
                <Stack key={index}>
                    <Typography
                        variant="caption"
                        fontWeight="700"
                        sx={{ textTransform: 'uppercase', fontSize: '1.1rem' }}
                    >
                        {item[0].title}
                    </Typography>
                    <List sx={{ display: 'flex', flexDirection: 'column', gap: '0.625rem', alignItems: 'flex-start' }}>
                        {item.map((subItem: any, subIndex: number) => {
                            if (subIndex === 0) {
                                return null;
                            }
                            return (
                                <ListItem
                                    key={subIndex}
                                    component={Link}
                                    href={subItem.link}
                                    disablePadding
                                    disableGutters
                                    target={subItem?.target}
                                    sx={{
                                        width: 'auto',
                                        '& span': {
                                            transition: '.25s',
                                            textTransform: 'uppercase',
                                            fontWeight: 700
                                        },
                                        '&:hover': {
                                            '& span': {
                                                color: 'text.primary'
                                            }
                                        }
                                    }}
                                >
                                    <ListItemText
                                        primary={subItem.title}
                                        primaryTypographyProps={{
                                            color: '#FFFFFF',
                                            variant: 'caption',
                                            fontWeight: 700,
                                            fontSize: '1rem'
                                        }}
                                    />
                                </ListItem>
                            );
                        })}
                    </List>
                </Stack>
            ))}
            <Stack direction="column" spacing={2}>
                {lgUp && <Image src={footerHeadIcons[0]} sx={{ width: 135 }} />}
                {/* <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    sx={{ bgcolor: '#0C1221', borderRadius: '10px', px: { md: 3, xs: 4 }, py: { md: 0.6, xs: 1 } }}
                >
                    <Image src={footerHeadIcons[1]} sx={{ width: 22.12, height: 26.25 }} />
                    <Stack>
                        <Typography sx={{ fontSize: 10 }}>Download</Typography>
                        <Typography sx={{ fontSize: 10 }}>app for IOS</Typography>
                    </Stack>
                </Stack>

                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    sx={{ bgcolor: '#0C1221', borderRadius: '10px', px: { md: 3, xs: 4 }, py: { md: 0.6, xs: 1 } }}
                >
                    <Image src={footerHeadIcons[2]} sx={{ width: 22.12, height: 26.25 }} />
                    <Stack>
                        <Typography sx={{ fontSize: 10 }}>Download</Typography>
                        <Typography sx={{ fontSize: 10 }}>app for Android</Typography>
                    </Stack>
                </Stack> */}
            </Stack>
        </Stack>
    );

    const renderMobileList = (
        <>
            <Stack direction="column" spacing={2} mb={2}>
                <Image src={footerHeadIcons[0]} sx={{ width: 135 }} />
                {/* <Stack direction="row" flexWrap="wrap"
                    spacing={2}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        sx={{ bgcolor: '#0C1221', borderRadius: '10px', px: { md: 3, xs: 4 }, py: { md: 0.6, xs: 1 } }}
                    >
                        <Image src={footerHeadIcons[1]} sx={{ width: 22.12, height: 26.25 }} />
                        <Stack>
                            <Typography sx={{ fontSize: 10 }}>Download</Typography>
                            <Typography sx={{ fontSize: 10 }}>app for IOS</Typography>
                        </Stack>
                    </Stack>
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        sx={{ bgcolor: '#0C1221', borderRadius: '10px', px: { md: 3, xs: 4 }, py: { md: 0.6, xs: 1 } }}
                    >
                        <Image src={footerHeadIcons[2]} sx={{ width: 22.12, height: 26.25 }} />
                        <Stack>
                            <Typography sx={{ fontSize: 10 }}>Download</Typography>
                            <Typography sx={{ fontSize: 10 }}>app for Android</Typography>
                        </Stack>
                    </Stack>
                </Stack> */}
            </Stack>
            {FooterLinks.map((item: any, index: number) => (
                <Accordion
                    key={index}
                    sx={{
                        '& .Mui-expanded,.MuiCollapse-root': {
                            background: '#1f1f1f'
                        }
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{ fontSize: '12px' }}
                    >
                        {item[0].title}
                    </AccordionSummary>
                    <AccordionDetails>
                        <List
                            sx={{ display: 'flex', flexDirection: 'column', gap: '0.625rem', alignItems: 'flex-start' }}
                        >
                            {item.map((subItem: any, subIndex: number) => {
                                if (subIndex === 0) {
                                    return null;
                                }
                                return (
                                    <ListItem
                                        key={subIndex}
                                        component={Link}
                                        href={subItem.link}
                                        disablePadding
                                        disableGutters
                                        target={subItem?.target}
                                        sx={{
                                            width: 'auto',
                                            '& span': {
                                                transition: '.25s'
                                            },
                                            '&:hover': {
                                                '& span': {
                                                    color: 'text.primary'
                                                }
                                            },
                                            '& .MuiPaper-root': { background: 'transparent' }
                                        }}
                                    >
                                        <ListItemText
                                            primary={subItem.title}
                                            primaryTypographyProps={{
                                                color: '#FFFFFF',
                                                variant: 'caption',
                                                fontWeight: 400
                                            }}
                                        />
                                    </ListItem>
                                );
                            })}
                        </List>
                    </AccordionDetails>
                </Accordion>
            ))}
        </>
    );
    const theme = useTheme();

    return (
        <>
            {smUp && (
                <AppBar
                    component="footer"
                    position="static"
                    sx={{
                        boxShadow: 'none',
                        backgroundImage: 'none',
                        bgcolor: '#1f1f1f'
                    }}
                >
                    <Stack
                        sx={{
                            py: 4
                        }}
                    >
                        <Container maxWidth={settings.themeStretch ? false : 'lg'}>
                            {/* {renderHead} */}

                            {lgUp ? renderList : renderMobileList}

                            <Carousel ref={carousel.carouselRef} {...carousel.carouselSettings}>
                                {_carousels.map((item, index: number) => (
                                    <CarouselItem key={item} item={item} />
                                ))}
                            </Carousel>

                            <Stack
                                spacing={2}
                                mt={{ md: 4, xs: 2 }}
                                direction={{ xs: 'column-reverse', md: 'row' }}
                                justifyContent="space-between"
                                alignItems={{ xs: 'center', md: 'flex-start' }}
                            >
                                {lgUp ? (
                                    <Typography
                                        fontSize="10px"
                                        color="white"
                                        maxWidth={{ md: '70%', xs: '100%' }}
                                        textAlign={{ xs: 'center', md: 'unset' }}
                                    >
                                        The maxbet.gold Casino Site is owned and operated by Gamblix N.V., a company
                                        incorporated under the laws of Curacao with company registration number 154209
                                        and having its registered address at Scharlooweg 39, Willemstad, Curacao,
                                        licensed by Antillephone N.V., Electronic Gaming License No. 10945/JAB,
                                        authorized by the Government of Curacao. We encourage you to play responsibly
                                        and consciously. Gaming should be a source of entertainment and pleasure, not
                                        stress or trouble. Set time and budget limits, and always stick to them. Please
                                        note that the maximum win is limited to 100,000 TND. For more information on our
                                        responsible gaming policy and terms of use, please see our Terms and Conditions.
                                    </Typography>
                                ) : (
                                    <Stack gap={2}>
                                        <Typography
                                            fontSize="10px"
                                            color="white"
                                            maxWidth={{ md: '70%', xs: '100%' }}
                                            textAlign={{ xs: 'center', md: 'unset' }}
                                        >
                                            Areadev.pro is operated by Gamblix Limited, which is registered in 1065,
                                            Sliema, Malta, registration No. MA 400062
                                        </Typography>
                                        <Typography fontSize="10px" color="white" maxWidth="100%" textAlign="center">
                                            2024 © Areadev.pro, all rights reserved
                                        </Typography>
                                    </Stack>
                                )}

                                <Stack direction="row" spacing={2} alignItems="center">
                                    {lgUp
                                        ? footerInfo.map((item, key) => (
                                              <Image
                                                  key={key}
                                                  src={item}
                                                  onClick={openLicense}
                                                  sx={{ cursor: 'pointer', '& img': { height: 'auto' } }}
                                              />
                                          ))
                                        : footerMobileInfo.map((item, key) => (
                                              <Image
                                                  key={key}
                                                  src={item}
                                                  onClick={openLicense}
                                                  sx={{ cursor: 'pointer', '& img': { height: 'auto' } }}
                                              />
                                          ))}
                                </Stack>
                            </Stack>
                        </Container>
                    </Stack>

                    <Dialog
                        fullWidth
                        maxWidth="md"
                        open={open}
                        onClose={handleClose}
                        sx={{ '& .MuiPaper-root': { margin: 0, width: '100%' } }}
                    >
                        <DialogContent sx={{ p: 0 }}>
                            <CloseIcon
                                sx={{
                                    position: 'absolute',
                                    right: 5,
                                    zIndex: 1,
                                    fontSize: 32,
                                    color: '#9d9d9d',
                                    cursor: 'pointer'
                                }}
                                onClick={handleClose}
                            />
                            <Image alt="License" src={`${ASSETS_API}/footer/license.png`} />
                        </DialogContent>
                    </Dialog>
                </AppBar>
            )}
            {mediaOnly && (
                <>
                    <AppBar component="footer" sx={{ height: 'auto', bgcolor: '#1f1f1f', top: 'inherit', bottom: 0 }}>
                        <Stack display="grid" gridTemplateColumns="repeat(5,1fr)">
                            <Link
                                href="/sports"
                                py={1.5}
                                borderRadius={0.5}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                rowGap="0.5rem"
                            >
                                <Image
                                    alt="Sports"
                                    src="/assets/icons/footer/sports.svg"
                                    width={25}
                                    height={25}
                                    sx={{
                                        borderRadius: 0
                                    }}
                                />
                                <Typography
                                    textTransform="uppercase"
                                    fontSize="0.75rem"
                                    lineHeight="normal"
                                    color={theme.palette.common.white}
                                >
                                    Sports
                                </Typography>
                            </Link>
                            <Link
                                href="/sports"
                                py={1.5}
                                borderRadius={0.5}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                rowGap="0.5rem"
                            >
                                <Image
                                    alt="Live"
                                    src="/assets/icons/footer/live.svg"
                                    width={25}
                                    height={25}
                                    sx={{
                                        borderRadius: 0
                                    }}
                                />
                                <Typography
                                    textTransform="uppercase"
                                    fontSize="0.75rem"
                                    lineHeight="normal"
                                    color={theme.palette.common.white}
                                >
                                    Live
                                </Typography>
                            </Link>
                            <Link
                                href="/casino"
                                py={1.5}
                                borderRadius={0.5}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                rowGap="0.5rem"
                            >
                                <Image
                                    alt="Games"
                                    src="/assets/icons/footer/games.svg"
                                    width={25}
                                    height={25}
                                    sx={{
                                        borderRadius: 0
                                    }}
                                />
                                <Typography
                                    textTransform="uppercase"
                                    fontSize="0.75rem"
                                    lineHeight="normal"
                                    color={theme.palette.common.white}
                                >
                                    Games
                                </Typography>
                            </Link>
                            <Link
                                href="/open/virtual"
                                py={1.5}
                                borderRadius={0.5}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                rowGap="0.5rem"
                            >
                                <Image
                                    alt="Virtuals"
                                    src="/assets/icons/footer/virtuals.svg"
                                    width={25}
                                    height={25}
                                    sx={{
                                        borderRadius: 0
                                    }}
                                />
                                <Typography
                                    textTransform="uppercase"
                                    fontSize="0.75rem"
                                    lineHeight="normal"
                                    color={theme.palette.common.white}
                                >
                                    Virtuals
                                </Typography>
                            </Link>
                            <Link
                                href="/live-casino"
                                py={1.5}
                                borderRadius={0.5}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                rowGap="0.5rem"
                            >
                                <Image
                                    alt="Live Games"
                                    src="/assets/icons/footer/live-games.svg"
                                    width={25}
                                    height={25}
                                    sx={{
                                        borderRadius: 0
                                    }}
                                />
                                <Typography
                                    textTransform="uppercase"
                                    fontSize="0.75rem"
                                    lineHeight="normal"
                                    color={theme.palette.common.white}
                                >
                                    Live Games
                                </Typography>
                            </Link>
                        </Stack>
                    </AppBar>
                </>
            )}
        </>
    );
};

export default Footer;

// ----------------------------------------------------------------------

function CarouselItem({ item }: { item: string }) {
    return (
        <Stack sx={{ maxHeight: 240, mr: 1, alignItems: 'center' }}>
            <Image alt={item} src={item} />
        </Stack>
    );
}
